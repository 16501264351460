import React from "react";
import { StoryCardBg } from "../../atoms/story-card-bg";
import PropTypes from "prop-types";
import Carousel from "../../atoms/carousel";
import "./two-col-story-inverse-verion-two.m.css";
import CardImage from "../../atoms/card-image";
import { Link } from "@quintype/components";

function TwoColStoryInverseVersionTwo({ collection }) {
  if (!collection.items) {
    return null;
  }
  const carouselName = "carousel-2-" + collection.id;
  const carouselOptions = {
    type: "carousel",
    autoplay: 5000,
    breakpoints: { 480: { perView: 1, peek: { before: 0, after: 80 } } },
    peek: { before: 0, after: 50 }
  };
  const desktopSlicedStorySet = collection.items.slice(0, 4);
  const mobileSlicedStorySet = collection.items.slice(1, 4);

  return (
    <div>
      <div styleName="two-col-story-inverse__container" className="full-width-with-padding">
        <div styleName="two-col-story-inverse-grid__container" key="stories">
          {desktopSlicedStorySet.map((item, index) => {
            const aspectRatio = "1200, 630";

            return (
              <StoryCardBg
                story={item.story}
                key={item.story.id}
                styleName="two-col-story-inverse__card"
                imageAspectRatio={aspectRatio}
                showSection
                index={index}
              />
            );
          })}
        </div>
      </div>
      <div styleName="mobile-version">
        <StoryCardBg
          story={collection.items[0].story}
          key={collection.items[0].story.id}
          styleName="first-video-card-image"
          imageAspectRatio="1200, 630"
          showSection
          mobileAspectRatio={[1200, 630]}
          desktopAspectRatio={[1200, 630]}
        />
        {mobileSlicedStorySet.length >= 1 && (
          <Carousel
            options={carouselOptions}
            carouselName={carouselName}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
          >
            {mobileSlicedStorySet.map((item, index) => {
              return (
                <Link href={`/${item.story.slug}`} key={index}>
                  <CardImage story={item.story} aspectRatio="1200, 630" imageType="image16x9" />
                </Link>
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
}
TwoColStoryInverseVersionTwo.propTypes = {
  collection: PropTypes.object
};

export default TwoColStoryInverseVersionTwo;
