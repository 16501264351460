import React from "react";
import { StoryCardBg } from "../../atoms/story-card-bg";
import PropTypes from "prop-types";

import "./two-col-story-inverse.m.css";
import { SectionName } from "../../atoms/section-name";

function TwoColStoryInverse({ collection }) {
  if (!collection.items) {
    return null;
  }
  return (
    <div styleName="two-col-story-inverse__container" className="full-width-with-padding">
      <SectionName name={collection.name} isDark key="collection-name" />,
      <div styleName="two-col-story-inverse-grid__container" key="stories">
        {collection.items.slice(0, 4).map((item, index) => {
          let aspectRatio = index === 1 ? "2,1" : "1,1";
          return (
            <StoryCardBg
              story={item.story}
              key={item.story.id}
              styleName="two-col-story-inverse__card"
              imageAspectRatio={aspectRatio}
              showSection
            />
          );
        })}
      </div>
    </div>
  );
}
TwoColStoryInverse.propTypes = {
  collection: PropTypes.object
};

export { TwoColStoryInverse };
