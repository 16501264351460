import React from "react";
import "./collection-name.m.css";
import { string } from "prop-types";
import { Link } from "@quintype/components";

const CollectionName = ({ collectionName, color, collectionSlug }) => {
  const style = {
    "border-left": `3px solid ${color}`
  };
  return (
    <div styleName="collection-name" className="collection-name" style={style}>
      <Link href={`/${collectionSlug}`}>{collectionName}</Link>
    </div>
  );
};

CollectionName.propTypes = {
  collectionName: string,
  color: string,
  collectionSlug: string
};

export default CollectionName;
