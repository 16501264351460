import React from "react";
import "./sub-headline.m.css";
import { string } from "prop-types";

const SubHeadline = ({ storySubHeadline }) => (
  <h3 styleName="subheadline" className="subheadline">
    {storySubHeadline}
  </h3>
);

SubHeadline.propTypes = {
  storySubHeadline: string
};

export default SubHeadline;
