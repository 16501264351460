import React from "react";
import "./headline.m.css";
import { string } from "prop-types";

const Headline = ({ storyHeadline }) => (
  <h3 styleName="headline" className="headline">
    {" "}
    {storyHeadline}{" "}
  </h3>
);

Headline.propTypes = {
  storyHeadline: string
};

export default Headline;
