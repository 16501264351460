import React from "react";

import "./full-background-image.m.css";
import { PropTypes } from "prop-types";

function FullBackgroundImage({ children, aspectRatio = "16,9" }) {
  const formattedAspectRatio = JSON.parse(`[${aspectRatio}]`);

  const imageAspectRatioStyle = {
    paddingTop: `${(formattedAspectRatio[1] / formattedAspectRatio[0]) * 100}%`
  };
  return (
    <div style={imageAspectRatioStyle} styleName="full-background-image">
      <div styleName="full-background-image__content">{children}</div>
    </div>
  );
}

FullBackgroundImage.propTypes = {
  children: PropTypes.node,
  aspectRatio: PropTypes.array
};

export { FullBackgroundImage };
