import React from "react";
import { StoryCard } from "../../atoms/story-card";
import { SectionName } from "../../atoms/section-name";
import "./four-col.m.css";
import PropTypes from "prop-types";

function FourCol({ config, collection = {} }) {
  if (!collection.items) {
    return null;
  }
  const sectionNameStyles = {
    marginBottom: "20px",
    color: "#000",
    fontFamily: "var(--font2)",
    textTransform: "capitalize"
  };
  return (
    <div>
      <SectionName name={collection.name} inlineStyles={sectionNameStyles} />
      <div styleName="four-col__container" key="stories">
        {collection.items.slice(0, 8).map(item => {
          return (
            <StoryCard
              story={item.story}
              config={config}
              imageAspectRatio={"16,9"}
              setHorizontalLayout={false}
              key={item.story.id}
              styleName="four-col__card"
            />
          );
        })}
      </div>
    </div>
  );
}

export { FourCol };

FourCol.propTypes = {
  config: PropTypes.object,
  collection: PropTypes.object
};
