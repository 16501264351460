/* eslint-disable jsx-a11y/img-redundant-alt, import/extensions */
import React from "react";
import { object, shape } from "prop-types";

import { getCollectionTemplate } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";

export const HomePage = props => (
  <div>
    <LazyCollection collection={props.data.collection} collectionTemplates={getCollectionTemplate} lazyAfter={1} />
  </div>
);

HomePage.propTypes = {
  data: shape({
    collection: object
  })
};
