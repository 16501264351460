import React, { useState } from "react";
import { Author } from "../../atoms/author";
import humanizeDate from "../../utils/time-format";
import CollectionName from "../../atoms/collection-name";
import Headline from "../../atoms/headline";
import "./four-col-grid-with-image.m.css";
import { object, string } from "prop-types";
import { StoryCardBg } from "../../atoms/story-card-bg";
import { Link } from "@quintype/components";
import get from "lodash/get";
import { DfpAd } from "../../atoms/dfp-ad";
import { refreshAdSlots } from "../../utils/utils";

const StoryCardBgWrapper = ({ story, bgColor }) => {
  const [color, setColor] = useState("transparent");
  return (
    <div
      styleName="content-wrapper"
      onMouseEnter={() => setColor(bgColor)}
      onMouseLeave={() => setColor("transparent")}
      style={{ "border-bottom": `6px solid ${color}` }}
    >
      <StoryCardBg
        story={story}
        key={story.id}
        showSection
        imageAspectRatio="1200, 630"
        mobileAspectRatio={[1200, 630]}
        desktopAspectRatio={[1200, 630]}
        className="story-card"
      />
      <Link href={`/${story.slug}`}>
        <div styleName="mobileContent">
          {story.headline && <Headline storyHeadline={story.headline} />}
          <div styleName="author-name-date">
            <time styleName="story-published-date">{humanizeDate(story["last-published-at"])}</time>
            <span styleName="divider">/</span>
            <Author authorName={story["author-name"]} />
          </div>
        </div>
      </Link>
    </div>
  );
};

class FourColGridWithImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {}
    };
  }

  componentDidUpdate() {
    refreshAdSlots();
  }

  render() {
    const { collection } = this.props;
    if (!collection.items) {
      return null;
    }
    const collectionSlug = collection.slug;
    const firstFourStories = collection.items.filter(i => i.type === "story").slice(0, 4);
    const collectionName = collection.name;
    const firstStory = collection.items[0].story;
    const color = get(firstStory, ["sections", "0", "data", "color"], "pink");
    const storiesData = firstFourStories.map(item => (
      <StoryCardBgWrapper story={item.story} key={item.story.id} bgColor={color} />
    ));
    return (
      <div styleName="wrapper">
        <CollectionName collectionName={collectionName} color={color} collectionSlug={collectionSlug} />
        {collectionSlug === "industry" && (
          <div styleName="sponsored-bio-ad">
            <DfpAd adtype="SponsoredBio" componentName={collectionSlug} />
          </div>
        )}
        <div styleName="collection-data">{storiesData}</div>
      </div>
    );
  }
}

export default FourColGridWithImage;

FourColGridWithImage.propTypes = {
  collection: object
};
StoryCardBgWrapper.propTypes = {
  story: object,
  bgColor: string
};
