import { wrapCollectionLayout } from "@quintype/components";
import { BleedingCarousel } from "./bleeding-carousel";
import { TwoCol } from "./two-col";
import { FourCol } from "./four-col";
import { TwoColStoryInverse } from "./two-col-story-inverse";
import { TwoColStoryInverse2 } from "./two-col-story-inverse2";
import { ThreeCol } from "./three-col";
import FourColWithoutImage from "./four-col-without-image";
import TwoRowThreeColWithImage from "./two-row-three-col-grid-with-image";
import FourColGridWithImage from "./four-col-grid-with-image";
import FourColGridWithImageVersionTwo from "./four-col-grid-with-image-version-two";
import TwoColStoryInverseVersionTwo from "./two-col-story-inverse-version-two";
import ThreeColTwoRowGrid from "./three-col-two-row-grid";
import TwoRowThreeColWithImageVersionTwo from "./two-row-three-col-grid-with-image-version-two";
import { TrendingNewsCarousel } from "../trending-news-carousel";
import { PartnerListing } from "./partner-listing";

export default {
  TwoCol: wrapCollectionLayout(TwoCol),
  ThreeCol: wrapCollectionLayout(ThreeCol),
  FourCol: wrapCollectionLayout(FourCol),
  TwoColStoryInverse: wrapCollectionLayout(TwoColStoryInverse),
  TwoColStoryInverse2: wrapCollectionLayout(TwoColStoryInverse2),
  BleedingCarousel: wrapCollectionLayout(BleedingCarousel),
  FourColWithoutImage: wrapCollectionLayout(FourColWithoutImage),
  TwoRowThreeColWithImage: wrapCollectionLayout(TwoRowThreeColWithImage),
  FourColGridWithImage: wrapCollectionLayout(FourColGridWithImage),
  FourColGridWithImageVersionTwo: wrapCollectionLayout(FourColGridWithImageVersionTwo),
  TwoColStoryInverseVersionTwo: wrapCollectionLayout(TwoColStoryInverseVersionTwo),
  ThreeColTwoRowGrid: wrapCollectionLayout(ThreeColTwoRowGrid),
  TwoRowThreeColWithImageVersionTwo: wrapCollectionLayout(TwoRowThreeColWithImageVersionTwo),
  TrendingNewsCarousel: wrapCollectionLayout(TrendingNewsCarousel),
  PartnerListing: wrapCollectionLayout(PartnerListing),
  defaultTemplate: wrapCollectionLayout(TrendingNewsCarousel)
};
