import React from "react";
import { StoryCardBg } from "../../atoms/story-card-bg";
import PropTypes from "prop-types";

import "./two-col-story-inverse2.m.css";
import { SectionName } from "../../atoms/section-name";

function TwoColStoryInverse2({ collection }) {
  if (!collection.items) {
    return null;
  }
  return (
    <div styleName="two-col-story-inverse2__container" className="full-width-with-padding">
      <SectionName name={collection.name} isDark key="collection-name" />
      <div styleName="two-col-story-inverse2-grid__container" key="stories">
        {collection.items.slice(0, 4).map((item, index) => {
          return (
            <StoryCardBg
              story={item.story}
              key={item.story.id}
              styleName="two-col-story-inverse2__card"
              imageAspectRatio={"16,9"}
              showSection
            />
          );
        })}
      </div>
    </div>
  );
}
TwoColStoryInverse2.propTypes = {
  collection: PropTypes.object
};

export { TwoColStoryInverse2 };
