import React from "react";
import { Author } from "../../atoms/author";
import humanizeDate from "../../utils/time-format";
import CollectionName from "../../atoms/collection-name";
import Headline from "../../atoms/headline";
import "./four-col-grid-with-image-version-two.m.css";
import { object } from "prop-types";
import { StoryCardBg } from "../../atoms/story-card-bg";
import { Link } from "@quintype/components";
import get from "lodash/get";
import CardImage from "../../atoms/card-image";
import Carousel from "../../atoms/carousel";
import Arrow1 from "./arrow-1";
import Arrow2 from "./arrow-2";

const FourColGridWithImageVersionTwo = ({ collection }) => {
  if (!collection.items) {
    return null;
  }
  const firstFourStories = collection.items.slice(0, 4);
  const collectionName = collection.name;
  const imageAspectRatio = "1200, 630";
  const firstStory = collection.items[0].story;
  const color = get(firstStory, ["sections", "0", "data", "color"], "pink");
  const carouselName = "carousel-2-" + collection.id;
  const carouselOptions = {
    type: "carousel",
    autoplay: 5000,
    perView: 4,
    gap: 24,
    breakpoints: { 480: { perView: 1, peek: { before: 0, after: 100 } } }
  };
  const collectionSlug = collection.slug;

  const desktopStoriesData = (
    <div styleName="desktop-content">
      <Carousel
        options={carouselOptions}
        carouselName={carouselName}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => {
          return (
            currentSlide !== 0 && (
              <button onClick={previousSlide} className="slider-btn left-slider-btn">
                <Arrow2 />
              </button>
            )
          );
        }}
        renderCenterRightControls={({ nextSlide }) => (
          <button onClick={nextSlide} className="slider-btn right-slider-btn">
            <Arrow1 />
          </button>
        )}
      >
        {collection.items.map((item, index) => {
          return (
            <Link href={`/${item.story.slug}`} key={index}>
              <CardImage story={item.story} aspectRatio="1200, 630" imageType="image16x9" />
            </Link>
          );
        })}
      </Carousel>
    </div>
  );

  const mobileStoriesData = (
    <div>
      {firstFourStories.map((item, index) => (
        <div styleName="content-wrapper" key={index}>
          <StoryCardBg
            story={item.story}
            key={item.story.id}
            showSection
            imageAspectRatio={imageAspectRatio}
            mobileAspectRatio={[1200, 630]}
            className="story-card"
          />
          <Link href={`/${item.story.slug}`}>
            <div styleName="mobileContent">
              {item.story.headline && <Headline storyHeadline={item.story.headline} />}
              <div styleName="author-name-date">
                <time styleName="story-published-date">{humanizeDate(item.story["last-published-at"])}</time>
                <span styleName="divider">/</span>
                <Author authorName={item.story["author-name"]} />
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );

  const storiesData = global.deviceWidth >= 768 ? desktopStoriesData : mobileStoriesData;
  return (
    <div styleName="wrapper">
      <CollectionName collectionName={collectionName} color={color} collectionSlug={collectionSlug} />
      <div styleName="collection-data">{storiesData}</div>
    </div>
  );
};

export default FourColGridWithImageVersionTwo;

FourColGridWithImageVersionTwo.propTypes = {
  collection: object
};
