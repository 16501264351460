import React from "react";
import { StoryCard } from "../../atoms/story-card";
import { SectionName } from "../../atoms/section-name";
import "./two-col.m.css";
import PropTypes from "prop-types";
import { DfpAd } from "../../atoms/dfp-ad";

function TwoCol({ stories, config, collection = {} }) {
  const sectionNameStyles = {
    marginBottom: "20px",
    color: "#000",
    fontFamily: "var(--font2)",
    textTransform: "capitalize"
  };
  if (!collection.items) {
    return null;
  }

  return (
    <div>
      <SectionName name={collection.name} inlineStyles={sectionNameStyles} />
      <div styleName="two-col__container">
        <div styleName="two-col__card">
          {collection.items.slice(0, 6).map(item => {
            return (
              <StoryCard
                story={item.story}
                config={config}
                imageAspectRatio={"16,9"}
                setHorizontalLayout
                showSummary
                key={item.story.id}
              />
            );
          })}
        </div>
        <div className="two-col__ad-wrapper">
          <div styleName="two-col__ad">
            <DfpAd adtype="Vertical-Ad" />
          </div>
          <div styleName="two-col__ad">
            <DfpAd adtype="Vertical-Ad-Bottom" />
          </div>
        </div>
      </div>
    </div>
  );
}

export { TwoCol };

TwoCol.propTypes = {
  stories: PropTypes.array,
  config: PropTypes.object,
  header: PropTypes.string,
  collection: PropTypes.object
};
