import React from "react";
import SectionNameBox from "../../atoms/section-name-box";
import { Author } from "../../atoms/author";
import humanizeDate from "../../utils/time-format";
import Headline from "../../atoms/headline";
import SubHeadline from "../../atoms/sub-headline";
import { Link } from "@quintype/components";
import "./four-col-without-image.m.css";
import { array } from "prop-types";
import get from "lodash/get";

class FourColWithoutImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {}
    };
  }

  getBorder(color, index) {
    this.setState({
      style: {
        [index]: {
          "border-bottom": `4px solid ${color}`
        }
      }
    });
  }

  render() {
    const { stories } = this.props;
    const firstFourStories = stories.slice(0, 4);
    const storiesData = firstFourStories.map((story, index) => {
      const color = get(story, ["sections", "0", "data", "color"], "#2F2BAD");
      const sectionSlug = get(story, ["sections", "0", "slug"]);
      return (
        <Link
          href={`/${story.slug}`}
          key={index}
          onMouseOver={() => this.getBorder(color, index)}
          onMouseOut={() => this.getBorder("transparent", index)}
          style={this.state.style[index]}
        >
          <div styleName="content-wrapper">
            <SectionNameBox
              sectionSlug={sectionSlug}
              sectionName={story.sections.length > 0 && story.sections[0].name}
              color={color}
            />
            {story.headline && <Headline storyHeadline={story.headline} />}
            {story.subheadline && <SubHeadline storySubHeadline={story.subheadline} />}
            <div styleName="author-name-date">
              <time styleName="story-published-date">{humanizeDate(story["last-published-at"])}</time>
              <span styleName="divider">/</span>
              <Author authorName={story["author-name"]} />
            </div>
          </div>
        </Link>
      );
    });

    return (
      <div styleName="wrapper">
        <div styleName="collection-data">{storiesData}</div>
      </div>
    );
  }
}

export default FourColWithoutImage;

FourColWithoutImage.propTypes = {
  stories: array
};
