import React from "react";

import "./not-found.m.css";

class NotFoundPage extends React.Component {
  componentDidMount() {
    window.location.href = "/";
  }
  render() {
    return (
      <div styleName="wrapper">
        <h1 styleName="status">404</h1>
        <p styleName="status-message">Oops! Seems like the page you are looking for does not exist.</p>
        <div>
          <a href="/" styleName="button">
            Return to Home Page
          </a>
        </div>
      </div>
    );
  }
}

export { NotFoundPage };
