import React from "react";
import "./partner-listing.m.css";
import get from "lodash/get";

const PartnerListing = props => {
  const htmlContent = get(props, ["associatedMetadata", "htmlContent"], "");
  return (
    <div styleName="wrapper">
      <h1 styleName="static-text">
        platform <br /> partners
      </h1>
      <div styleName="partners-logo" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export { PartnerListing };
