import React from "react";
import "./section-name-box.m.css";
import { string } from "prop-types";
import { Link } from "@quintype/components";

const SectionNameBox = ({ sectionName, color, sectionSlug }) => {
  const style = {
    backgroundColor: color
  };
  return (
    <Link href={`/${sectionSlug}`} styleName="name" className="name" style={style}>
      {sectionName}
    </Link>
  );
};

SectionNameBox.propTypes = {
  sectionName: string,
  color: string,
  sectionSlug: string
};

export default SectionNameBox;
