import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import Carousel from "../../atoms/carousel";
import { FullBackgroundImage } from "../../atoms/full-bg-image";
import "./bleeding-carousel.m.css";
import { OptimizedImage } from "../../atoms/optimized-image";
import SectionNameBox from "../../atoms/section-name-box";

function BleedingCarousel({ collection, config }) {
  if (!collection.items) {
    return null;
  }

  const collectionStories = collection.items.slice(0, 4);
  return (
    <div styleName="bleeding-carousel">
      <Carousel
        options={CAROUSEL_OPTIONS}
        carouselName={"carousel-" + collection.id}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
      >
        {collectionStories.map(item => {
          return (
            <BleedingCarouselItem
              story={item.story}
              key={item.story.id}
              showContent={config["publisher-attributes"]["show_carousel_content"]}
            />
          );
        })}
      </Carousel>
    </div>
  );
}
BleedingCarousel.propTypes = {
  collection: PropTypes.object,
  config: PropTypes.object
};

class BleedingCarouselItem extends React.Component {
  render() {
    const color = get(this.props.story, ["sections", "0", "data", "color"], "#2F2BAD");
    const sectionSlug = get(this.props.story, ["sections", "0", "slug"]);
    const style = {
      color: color
    };

    return (
      <Link href={`/${this.props.story.slug}`} styleName="bleeding-carousel__slide">
        <FullBackgroundImage aspectRatio={[1200, 630]}>
          <OptimizedImage
            slug={this.props.story["hero-image-s3-key"]}
            metadata={this.props.story["hero-image-metadata"]}
            desktopAspectRatio={[1200, 630]}
            mobileAspectRatio={[1200, 630]}
            sizes="98%"
          />
        </FullBackgroundImage>
        {this.props.showContent && (
          <div styleName="bleeding-carousel__content-wrapper">
            <div styleName="bleeding-carousel__content">
              {this.props.story.sections && (
                <SectionNameBox
                  sectionName={this.props.story.sections[0].name}
                  color={color}
                  styleName="section-name"
                  sectionSlug={sectionSlug}
                />
              )}
              <h2>
                <span styleName="bleeding-carousel__title-content" style={style}>
                  {" "}
                  {this.props.story.headline}{" "}
                </span>
              </h2>
              {this.props.story.subheadline && <p styleName="sub-headline">{this.props.story.subheadline}</p>}
              <span styleName="read-more-link">Read More</span>
            </div>
          </div>
        )}
      </Link>
    );
  }
}

BleedingCarouselItem.propTypes = {
  story: PropTypes.object,
  showContent: PropTypes.boolean
};

BleedingCarouselItem.defaultProps = {
  story: {}
};

const CAROUSEL_OPTIONS = {
  type: "carousel",
  autoplay: 5000
};

export { BleedingCarousel };
