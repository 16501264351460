import React from "react";
import { LoadMoreStoriesBase } from "@quintype/components";
import PropTypes from "prop-types";
import { TrendingNewsCarousel } from "../../trending-news-carousel";
import { StoryCard } from "../../atoms/story-card";
import { Loadmore } from "../../atoms/load-more";
import "./tag-page.m.css";
import { DfpAd } from "../../atoms/dfp-ad";
import get from "lodash/get";

class TagPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTrendingStories: false
    };
  }
  componentDidMount() {
    this.setState({
      showTrendingStories: true
    });
  }
  render() {
    const data = this.props.data;
    const fulFillmentTrendingStories = get(data, ["trendingStories", "fulfillmentValue"], []);
    const trendingStories = fulFillmentTrendingStories.map(trendingStory => trendingStory.story);
    const storyFields =
      "headline,subheadline,summary,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template";

    return (
      <div styleName="tag-wrapper">
        {this.state.showTrendingStories &&
          trendingStories.length > 1 && <TrendingNewsCarousel stories={trendingStories} config={data.config.config} />}
        <div>
          {data.stories.length ? (
            <h1 styleName="heading">
              Results For &#34;
              <span styleName="text-result">{decodeURI(data.tag.name)}</span> &#34;
            </h1>
          ) : (
            <h1 styleName="heading">
              No stories found for &#34;
              <span styleName="text-result">{data.tag.name}</span> &#34;
            </h1>
          )}

          <LoadMoreStoriesBase
            template={MoreCollectionStories}
            fields={storyFields}
            params={{ "tag-slugs": data.tag.slug }}
            data={data}
            numStoriesToLoad={10}
          />
        </div>
        <div styleName="dfp">
          <DfpAd adtype="Vertical-Ad" />
        </div>
      </div>
    );
  }
}

export { TagPage };

function MoreCollectionStories({ stories, onLoadMore, noMoreStories }) {
  return (
    <div>
      <div>
        {stories.length
          ? stories.map((item, key) => {
              return <StoryCard story={item} imageAspectRatio="16,9" setHorizontalLayout key={key} />;
            })
          : null}
      </div>
      <div>{!noMoreStories && stories.length >= 10 ? <Loadmore onClick={onLoadMore} /> : null}</div>
    </div>
  );
}

TagPage.propTypes = {
  data: PropTypes.object
};

MoreCollectionStories.propTypes = {
  stories: PropTypes.object,
  onLoadMore: PropTypes.function,
  noMoreStories: PropTypes.number
};
