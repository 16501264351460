/* eslint no-undef: "error" */
import React from "react";
import "./three-col-two-row-grid.m.css";
import { Author } from "../../atoms/author";
import humanizeDate from "../../utils/time-format";
import CollectionName from "../../atoms/collection-name";
import Headline from "../../atoms/headline";
import SubHeadline from "../../atoms/sub-headline";
import { object } from "prop-types";
import { StoryCardBg } from "../../atoms/story-card-bg";
import { Link } from "@quintype/components";
import get from "lodash/get";

class ThreeColTwoRowGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {}
    };
  }

  getBorder(color, index) {
    this.setState({
      style: {
        [index]: {
          borderBottom: `4px solid ${color}`
        }
      }
    });
  }

  render() {
    const { collection } = this.props;
    const collectionSlug = collection.slug;
    const firstFiveStories = collection.items.slice(0, 5);
    const collectionName = collection.name;
    const firstStory = collection.items[0].story;
    const color = get(firstStory, ["sections", "0", "data", "color"], "#2F2BAD");

    const storiesData = firstFiveStories.map((item, index) => {
      const story = item.story;
      return (
        <div
          styleName="content-wrapper"
          key={index}
          onMouseOver={() => this.getBorder(color, index)}
          onMouseOut={() => this.getBorder("transparent", index)}
          style={this.state.style[index]}
        >
          <StoryCardBg
            story={story}
            key={story.id}
            styleName="responsive-image"
            showSection
            imageAspectRatio={"1200, 630"}
            mobileAspectRatio={[1200, 630]}
            desktopAspectRatio={[1200, 630]}
            className="story-card"
          />
          <Link href={`/${story.slug}`}>
            <div styleName="mobileContent">
              {story.headline && <Headline storyHeadline={story.headline} />}
              <SubHeadline storySubHeadline={story.subheadline} />
              <div styleName="author-name-date">
                <time styleName="story-published-date">{humanizeDate(story["last-published-at"])}</time>
                <span styleName="divider">/</span>
                <Author authorName={story["author-name"]} />
              </div>
            </div>
          </Link>
        </div>
      );
    });
    return (
      <div styleName="wrapper">
        <CollectionName collectionName={collectionName} color={color} collectionSlug={collectionSlug} />
        <div styleName="collection-data">{storiesData}</div>
      </div>
    );
  }
}

export default ThreeColTwoRowGrid;

ThreeColTwoRowGrid.propTypes = {
  collection: object
};
