import React from "react";
import { PropTypes } from "prop-types";
import { Link, SocialShare } from "@quintype/components";
import { SocialShareTemplateHover } from "../../story-templates/social-share-hover";
import "./story-card-bg.m.css";
import { PlayButton } from "../../atoms/play-button";
import { SectionName } from "../../atoms/section-name";
import { Author } from "../author";
import get from "lodash/get";
import { getSubstring } from "../../utils/utils";
import { OptimizedImage } from "../optimized-image";

// StoryCardBg have three different behaviour in mobile only.
// One: Keep the actual design as desktop. ie content is on top of the image. (no extra prop needed)
// Two: Have a thumbnail behaviour. ie show image on top and content on below. (isThumbnailTemplate should be true)
// Three: Horizontal: ie image on left and content on right. (isHorizontalTemplate should be true)
// Don't send both `isThumbnailTemplate` and `isHorizontalTemplate` prop as true for one StoryCardBg component.

function StoryCardBg({
  story,
  showSection,
  showAuthor,
  titleStyles,
  className = "",
  imageAspectRatio = "4,3",
  isThumbnailTemplate,
  isHorizontalTemplate,
  index,
  mobileAspectRatio,
  desktopAspectRatio
}) {
  // Support both collection story and normal story object.

  const storyObject = get(story, "type") === "story" ? get(story, "story") : story;

  const formattedAspectRatio = JSON.parse(`[${imageAspectRatio}]`);

  const imageAspectRatioStyle = {
    paddingTop: `${(formattedAspectRatio[1] / formattedAspectRatio[0]) * 100}%`
  };

  const titleClassName = index === 0 ? "storyHeadlineT-1" : "storyHeadlineT-2";

  return storyObject ? (
    <div styleName={"story-card-bg__wrapper"} className={className}>
      <div styleName="social-share" className="social-share">
        <SocialShare
          template={SocialShareTemplateHover}
          color="#1c1c1c"
          title={storyObject.headline}
          url={storyObject.slug}
        />
      </div>
      <Link
        styleName={`story-card ${isHorizontalTemplate ? "story-card_horizontal-mobile" : ""}`}
        href={`/${storyObject.slug}`}
      >
        <OptimizedImage
          slug={storyObject["hero-image-s3-key"]}
          metadata={storyObject["hero-image-metadata"]}
          alt={storyObject.headline}
          aspectRatio={formattedAspectRatio}
          sizes="(max-width: 500px) 98vw, 48vw"
          style={imageAspectRatioStyle}
          styleName="image"
          mobileAspectRatio={mobileAspectRatio}
          desktopAspectRatio={desktopAspectRatio}
          className={className}
          desktopWidths={[180, 360]}
          mobileWidths={[172, 327]}
        />
        {isThumbnailTemplate ? (
          <div styleName="thumbnail-story-content" className="thumbnail-play-image">
            {storyObject["story-template"] === "video" && (
              <div styleName="thumbnail-play-image">
                {" "}
                <PlayButton type="small" />{" "}
              </div>
            )}
            <h3 styleName="thumbnail-story-title">{getSubstring(storyObject.headline, 85)}</h3>
          </div>
        ) : (
          <div styleName="content">
            {storyObject["story-template"] === "video" && (
              <div className="play-image">
                {" "}
                <PlayButton type="small" />{" "}
              </div>
            )}
            {showSection && (
              <SectionName
                styleName="section"
                className="section"
                name={get(story, ["sections", 0, "display-name"]) || get(story, ["sections", 0, "name"])}
                isExtraSmall
              />
            )}
            <h3 styleName="title" className={titleClassName} style={titleStyles}>
              {getSubstring(storyObject.headline, 85)}
            </h3>
            {showAuthor ? <Author authorName={storyObject["author-name"]} /> : null}
          </div>
        )}
      </Link>
    </div>
  ) : null;
}

StoryCardBg.propTypes = {
  story: PropTypes.object,
  showSection: PropTypes.bool,
  showAuthor: PropTypes.bool,
  titleStyles: PropTypes.object,
  imageAspectRatio: PropTypes.string,
  isThumbnailTemplate: PropTypes.bool,
  isHorizontalTemplate: PropTypes.bool,
  className: PropTypes.string,
  index: PropTypes.number,
  desktopAspectRatio: PropTypes.string,
  mobileAspectRatio: PropTypes.string
};

export { StoryCardBg };
