import React from "react";
import { PropTypes } from "prop-types";
import { Loadmore } from "../../atoms/load-more";
import "./section-stories-grid.m.css";
import { StoryCard } from "../../atoms/story-card";

export function MagazineSectionStoriesGridTemplate({ stories, onLoadMore, noMoreStories }) {
  return (
    <React.Fragment>
      <div styleName="stories magazine-stories">
        {stories.length ? (
          stories.map((item, key) => {
            return (
              <StoryCard
                story={item}
                imageAspectRatio={"320,380"}
                setHorizontalLayout={false}
                key={item.id}
                mobileAspectRatio={"320, 380"}
                desktopAspectRatio={"320, 380"}
              />
            );
          })
        ) : (
          <div>
            <h3>No stories found...</h3>
          </div>
        )}
      </div>
      <div>{!noMoreStories && stories.length >= 13 ? <Loadmore onClick={onLoadMore} /> : null}</div>
    </React.Fragment>
  );
}

MagazineSectionStoriesGridTemplate.propTypes = {
  stories: PropTypes.object,
  onLoadMore: PropTypes.function,
  noMoreStories: PropTypes.number
};
