import React from "react";
import { StoryCardBg } from "../../atoms/story-card-bg";

import "./three-col.m.css";
import { SectionName } from "../../atoms/section-name";
import { DfpAd } from "../../atoms/dfp-ad";

function ThreeCol({ stories, collection }) {
  if (!collection.items) {
    return null;
  }
  return [
    <SectionName name={collection.name} key="collection-name" />,
    <div styleName="three-col__container" key="stories">
      {collection.items.slice(0, 8).map((item, index) => {
        let aspectRatio = index === 1 || index === 4 ? "383,289" : "383,185";
        return (
          <StoryCardBg
            story={item.story}
            key={item.story.id}
            styleName="three-col__card"
            imageAspectRatio={aspectRatio}
            showSection
          />
        );
      })}
    </div>,
    <DfpAd adtype="Horizontal-Ad" key="ad" styleName="ad" />
  ];
}

export { ThreeCol };
