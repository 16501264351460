import React from "react";
import { Author } from "../../atoms/author";
import humanizeDate from "../../utils/time-format";
import CollectionName from "../../atoms/collection-name";
import Headline from "../../atoms/headline";
import SubHeadline from "../../atoms/sub-headline";
import "./two-row-three-col-with-image.m.css";
import { object } from "prop-types";
import { StoryCardBg } from "../../atoms/story-card-bg";
import { Link } from "@quintype/components";
import get from "lodash/get";

class TwoRowThreeColWithImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {}
    };
  }

  getBorder(color, index) {
    this.setState({
      style: {
        [index]: {
          borderBottom: `6px solid ${color}`
        }
      }
    });
  }

  render() {
    const { collection } = this.props;
    if (!collection.items) {
      return null;
    }
    const collectionSlug = collection.slug;
    const firstsixStories = collection.items.slice(0, 6);
    const collectionName = collection.name;
    const imageAspectRatio = "1200, 630";
    const firstStory = collection.items[0].story;
    const color = get(firstStory, ["sections", "0", "data", "color"], "#2F2BAD");

    const storiesData = firstsixStories.map((item, index) => (
      <div
        styleName="content-wrapper"
        key={index}
        onMouseOver={() => this.getBorder(color, index)}
        onMouseOut={() => this.getBorder("transparent", index)}
        style={this.state.style[index]}
      >
        <StoryCardBg
          story={item.story}
          key={item.story.id}
          showSection
          imageAspectRatio={imageAspectRatio}
          mobileAspectRatio={[1200, 630]}
          desktopAspectRatio={[1200, 630]}
          className="story-card"
          styleName="resposiveImage"
        />
        <Link href={`/${item.story.slug}`}>
          <div styleName="mobileContent">
            {item.story.headline && <Headline storyHeadline={item.story.headline} />}
            {item.story.subheadline && <SubHeadline storySubHeadline={item.story.subheadline} />}
            <div styleName="author-name-date">
              <time styleName="story-published-date">{humanizeDate(item.story["last-published-at"])}</time>
              <span styleName="divider">/</span>
              <Author authorName={item.story["author-name"]} />
            </div>
          </div>
        </Link>
      </div>
    ));

    return (
      <div styleName="wrapper">
        <CollectionName collectionName={collectionName} color={color} collectionSlug={collectionSlug} />
        <div styleName="collection-data">{storiesData}</div>
      </div>
    );
  }
}

export default TwoRowThreeColWithImage;

TwoRowThreeColWithImage.propTypes = {
  collection: object
};
