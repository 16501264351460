import React from "react";
import concat from "lodash/concat";
import { TrendingNewsCarousel } from "../../trending-news-carousel";
import { StoryCard } from "../../atoms/story-card";
import { Loadmore } from "../../atoms/load-more";
import "./search-page.m.css";
import { DfpAd } from "../../atoms/dfp-ad";
import PropTypes from "prop-types";
import wretch from "wretch";
import get from "lodash/get";

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stories: this.props.data.stories,
      totalNumberOfStories: this.props.data.total,
      offset: this.props.data.stories.length,
      query: this.props.data.query,
      limit: 8,
      showTrendingStories: false
    };
    this.getMoreResults = this.getMoreResults.bind(this);
  }
  componentDidMount() {
    this.setState({
      showTrendingStories: true
    });
  }
  getMoreResults() {
    const url = "/api/v1/search?q=" + this.state.query + "&offset=" + this.state.offset + "&limit=" + this.state.limit;
    wretch()
      .url(url)
      .get()
      .json(({ results }) => {
        const updatedResults = concat(this.state.stories, results.stories);
        this.setState({
          stories: updatedResults,
          offset: this.state.offset + this.state.limit
        });
      });
  }
  render() {
    const fulfillmentTrendingStories = get(this.props, ["data", "trendingStories", "fulfillmentValue"], []);
    const trendingStories = fulfillmentTrendingStories.map(trendingStory => trendingStory.story);

    return (
      <React.Fragment>
        {this.state.showTrendingStories &&
          trendingStories.length > 1 && <TrendingNewsCarousel stories={trendingStories} config={this.props.config} />}
        <h1 styleName="search-result-title">
          Search Results for &#39;
          <p styleName="search-result-query">{this.props.data.query}</p>
          &#39;
        </h1>
        <div styleName="two-column-grid">
          <div styleName="first-column-stories">
            {this.state.stories.length ? (
              this.state.stories.map((item, key) => (
                <StoryCard key={key} story={item} setHorizontalLayout showSection showSummary showAuthor />
              ))
            ) : (
              <div className="component-wrapper">
                <p>
                  No stories found for &#39;
                  <strong>{this.props.data.query}</strong>
                  &#39;.
                </p>
              </div>
            )}
            {this.state.stories.length < this.state.totalNumberOfStories ? (
              <Loadmore onClick={this.getMoreResults} />
            ) : null}
          </div>
          <div styleName="dfp-add">
            <DfpAd adtype="Vertical-Ad" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SearchPage.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    stories: PropTypes.array,
    query: PropTypes.string
  }),
  config: PropTypes.array
};

export { SearchPage };
