import React from "react";
import { PropTypes } from "prop-types";
import { Loadmore } from "../../atoms/load-more";
import { StoryCard } from "../../atoms/story-card";

import "./section-stories-grid.m.css";

export function SectionStoriesGridTemplate({ stories, onLoadMore, noMoreStories }) {
  return (
    <React.Fragment>
      <div styleName="stories">
        {stories.length ? (
          stories.slice(5).map((item, key) => {
            return <StoryCard story={item} imageAspectRatio={"1200,630"} setHorizontalLayout={false} key={item.id} />;
          })
        ) : (
          <div>
            <h3>No stories found...</h3>
          </div>
        )}
      </div>
      <div>{!noMoreStories && stories.length >= 13 ? <Loadmore onClick={onLoadMore} /> : null}</div>
    </React.Fragment>
  );
}

SectionStoriesGridTemplate.propTypes = {
  stories: PropTypes.object,
  onLoadMore: PropTypes.function,
  noMoreStories: PropTypes.number
};
