import React from "react";
import { LoadMoreStoriesBase, Link } from "@quintype/components";
import { PropTypes } from "prop-types";
import { SectionStoriesGridTemplate } from "./section-stories-grid.js";
import { MagazineSectionStoriesGridTemplate } from "./magazine-section-stories-grid.js";
import Carousel from "../../atoms/carousel";
import "./section-stories-grid.m.css";
import { OptimizedImage } from "../../atoms/optimized-image";
import { FullBackgroundImage } from "../../atoms/full-bg-image";
import SectionNameBox from "../../atoms/section-name-box";
import get from "lodash/get";
import { TrendingNewsCarousel } from "../../trending-news-carousel";
import DesktopDetails from "../../../../assets/images/Banners-01.png";
// import MobileDetails from "../../../../assets/images/Banners-02.png";
import { PartnerListing } from "../../collection-templates/partner-listing";
import assetify from "@quintype/framework/assetify";
import { NextButton } from "../../atoms/icons/next-button";
import { getParentSection } from "../../utils/utils";
import { DfpAd } from "../../atoms/dfp-ad";

const SectionPage = ({ data = { stories: [] } }) => {
  const carouselName = "carousel-2-" + data.collection.id;
  const carouselOptions = {
    type: "carousel",
    autoplay: 5000
  };

  const collectionItems = get(data, ["collection", "items"], []).filter(items => items.type === "collection");
  const associatedMetadata = collectionItems[0] && collectionItems[0]["associated-metadata"];

  const trendingStories = data.trendingStories && data.trendingStories.map(trendingStory => trendingStory.story);
  const gridTemplate =
    data.collection.slug === "magissues" ? MagazineSectionStoriesGridTemplate : SectionStoriesGridTemplate;

  const parentSection = getParentSection(data);

  const storyFields =
    "headline,subheadline,summary,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template";

  const filtredStories = data.stories.filter(i => i.type === "story");

  return (
    <div>
      {trendingStories.length > 1 && <TrendingNewsCarousel stories={trendingStories} config={data.config.config} />}
      <div styleName="breadcrumb-wrapper">
        <Link styleName="home-link" href="https://www.sme10x.com">
          HOME
        </Link>
        {parentSection ? (
          <React.Fragment>
            <NextButton height={14} width={14} />
            <Link styleName="home-link" href={`/${parentSection.slug}`}>
              {parentSection["display-name"]}
            </Link>
          </React.Fragment>
        ) : null}
        <NextButton height={14} width={14} />
        <h1 styleName="collection-name">{data.collection.name}</h1>
      </div>
      {data.collection.slug !== "magissues" && filtredStories.length > 1 ? (
        <div styleName="bleeding-carousel">
          <Carousel
            options={carouselOptions}
            carouselName={carouselName}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
          >
            {filtredStories.slice(0, 5).map((story, index) => {
              return <SectionCarouselItem story={story} key={index} showContent={false} />;
            })}
          </Carousel>
        </div>
      ) : null}
      {associatedMetadata &&
        data.collection.slug === "industry" && (
          <PartnerListing {...collectionItems} associatedMetadata={associatedMetadata} />
        )}

      <div styleName="sponsored-bio-ad">
        <DfpAd adtype="SponsoredBio" sectionName={data.collection.slug} />
      </div>

      {data.collection.slug === "magissues" && (
        <div>
          <div styleName="desktop-details">
            <img src={assetify(DesktopDetails)} />
          </div>
          {/* <div styleName="mobile-details">
            <img src={assetify(MobileDetails)} />
          </div> */}
        </div>
      )}

      {filtredStories.length > 5 ? (
        <LoadMoreStoriesBase template={gridTemplate} fields={storyFields} data={data} numStoriesToLoad={16} />
      ) : null}
    </div>
  );
};

SectionPage.propTypes = {
  data: PropTypes.object
};

class SectionCarouselItem extends React.Component {
  constructor() {
    super();
    this.state = {
      aspectRatio: [1200, 630]
    };
  }
  render() {
    const color = get(this.props.story.story, ["sections", "0", "data", "color"], "#2F2BAD");
    const sectionSlug = get(this.props.story.story, ["sections", "0", "slug"]);
    const style = {
      color: color
    };

    return (
      <Link href={`/${this.props.story.story.slug}`} styleName="bleeding-carousel__slide">
        <FullBackgroundImage aspectRatio={this.state.aspectRatio}>
          <OptimizedImage
            slug={this.props.story.story["hero-image-s3-key"]}
            metadata={this.props.story.story["hero-image-metadata"]}
            desktopAspectRatio={this.state.aspectRatio}
            mobileAspectRatio={this.state.aspectRatio}
            sizes="98%"
          />
        </FullBackgroundImage>
        {this.props.showContent && (
          <div styleName="bleeding-carousel__content-wrapper">
            <div styleName="bleeding-carousel__content">
              {this.props.story.story.sections && (
                <SectionNameBox
                  sectionName={this.props.story.story.sections[0].name}
                  color={color}
                  styleName="section-name"
                  sectionSlug={sectionSlug}
                />
              )}
              <h2>
                <span styleName="bleeding-carousel__title-content" style={style}>
                  {this.props.story.story.headline}{" "}
                </span>
              </h2>
              {this.props.story.story.subheadline && (
                <p styleName="sub-headline">{this.props.story.story.subheadline}</p>
              )}
              <span styleName="read-more-link">Read More</span>
            </div>
          </div>
        )}
      </Link>
    );
  }
}

SectionCarouselItem.propTypes = {
  story: PropTypes.object,
  showContent: PropTypes.boolean
};

SectionCarouselItem.defaultProps = {
  story: {}
};

export { SectionPage };
