import React from "react";
import "./styles.m.css";
import PropTypes from "prop-types";

function Loadmore({ onClick, children }) {
  return (
    <div styleName="wrapper">
      <button styleName="load-more" onClick={onClick}>
        {children || "Load More"}
      </button>
    </div>
  );
}
Loadmore.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node
};

export { Loadmore };
